/*Kyle Salstrom - 2015*/
/*kylesalstrom@gmail.com*/

body {
	background-color: black;
}
video {
	margin-left: 	auto;
	margin-right: 	auto;
	display: 	block;
	height:		60vh;
	padding-top:	3vh;
	padding-bottom:	1vh;
}
div{
	text-align:	center;
	margin-left: 	auto;
	margin-right: 	auto;
}
img.bigImg{
	height:		18vh;
	padding-top: 5vh;
	padding-left: 	2vh;
	padding-right: 	2vh;
	margin-top: -3vh;
}
img.medImg{
	height:		4.4vh;
	margin-bottom: 	-8px;
}
img.smlImg{
	height:		3vh;
	margin-left: 	-4px;
	margin-right: 	-4px;
}
img.tnyImg{
	height:		2vh;
}
ul.share-buttons{
	list-style: 	none;
	padding: 	0;
}
ul.share-buttons li{
	display: 	inline;
}
@media (max-width: 600px) {
	video {
		width:			100%;
		padding-top:	1px;
		margin-bottom: -4vh;
	}
	img.bigImg{
		height:		15vh;
		margin-top: -4.5vh;
		margin-bottom: -3.5vh;
	}
	img.medImg{
		height:		2.8vh;
		margin-bottom: 	-18px;
	}
	img.smlImg{
		height:		2.5vh;
		margin-left: 	-2px;
		margin-right: 	-2px;
	}
	img.tnyImg{
		height:		1.5vh;
	}
	img.smlTD{
		padding:		0px;
	}
}